import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import SupportTeam from './pages/SupportTeam';
import Gallery from './pages/Gallery';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import NoPage from './components/NoPage';
import Layout from './components/Layout';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import ReturnRefundPolicy from './pages/ReturnRefundPolicy';
import ScrollToTop from './utils/ScrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";
import AppStore from './pages/AppStore';
import AppDetails from './components/app-details/AppDetails';

export default function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 200,
      once: true, 
    });

    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="support-team" element={<SupportTeam />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="features" element={<Features />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="app-store" element={<AppStore />} />
          <Route path="app-store/download/:appName" element={<AppDetails />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-conditions" element={<TermsConditions />} />
          <Route path="return-refund-policy" element={<ReturnRefundPolicy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
