//Contact
import React from "react";
import ContactSection from "../components/contact/ContactSection";

const Contact = () => {
  return (
    <div className="pt-10">
      <ContactSection/>
    </div>
  );
};

export default Contact;
