import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Layout = () => {
  return (
    <div className="bg-white container mx-auto">
      <div className="container fixed top-4 z-40">
        <div className="w-5/6 mx-auto">
          <Navbar />
        </div>
      </div>
      <Outlet />
      <div className="container mx-auto mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
