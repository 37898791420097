import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const navLinks = [
    {
      title: "Main Links",
      links: [
        { name: "Home", path: "/" },
        { name: "About Us", path: "/about-us" },
        { name: "Support Team", path: "/support-team" },
        { name: "Gallery", path: "/gallery" },
        { name: "Features", path: "/features" },
        { name: "Pricing", path: "/pricing" },
        { name: "Contact Us", path: "/contact-us" },
        { name: "App Store", path: "/app-store" },
      ],
    },
    {
      title: "Policies",
      links: [
        { name: "Privacy Policy", path: "/privacy-policy" },
        { name: "Terms & Conditions", path: "/terms-conditions" },
        { name: "Return & Refund Policy", path: "/return-refund-policy" },
      ],
    },
  ];

  return (
    <section
      className="relative mt-2 flex items-center py-10 shadow-xl"
      style={{ boxShadow: "0px -1px 5px #0000002c" }}
    >
      <div className="w-full">
        <div className="container mx-auto">
          <div className="md:py-8">
            <div className="flex flex-col lg:flex-row lg:justify-between space-y-8 lg:space-y-0 w-5/6 mx-auto">
              {/* Left Section */}
              <div className="lg:w-1/2">
                <NavLink
                  to="/"
                  className="inline-block"
                  aria-label="NetFee Home"
                >
                  <img
                    src="/logo/netfee2.png"
                    alt="NetFee Logo"
                    loading="lazy"
                    height="30"
                    width="150"
                  />
                </NavLink>
                <p className="mt-5 text-black text-sm sm:text-base">
                  Empowering seamless internet billing <br />
                  just the way it should be!
                </p>
                <p className="mt-5 text-gray-700 text-xs sm:text-sm">
                  Copyright © Shunno IT. 2024 All Rights Reserved
                </p>
              </div>

              {/* Right Section */}
              <div className="lg:w-1/2 flex flex-col lg:flex-row lg:justify-center space-y-6 lg:space-y-0 lg:space-x-12 text-gray-700">
                {navLinks.map((section, index) => (
                  <div key={index} className="w-full">
                    <h4 className="font-bold text-gray-800 mb-2">
                      {section.title}
                    </h4>

                    {/* Special handling for Main Links */}
                    {section.title === "Main Links" ? (
                      <div className="flex flex-wrap lg:flex-nowrap">
                        {/* Split links for larger screens */}
                        <ul className="lg:w-1/2 space-y-2 hidden lg:block">
                          {section.links.slice(0, 4).map((link, i) => (
                            <li key={i}>
                              <NavLink
                                to={link.path}
                                className={({ isActive }) =>
                                  isActive
                                    ? "text-black border-b-2 border-black"
                                    : "hover:underline"
                                }
                              >
                                {link.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                        <ul className="lg:w-1/2 space-y-2 hidden lg:block">
                          {section.links.slice(4).map((link, i) => (
                            <li key={i}>
                              <NavLink
                                to={link.path}
                                className={({ isActive }) =>
                                  isActive
                                    ? "text-black border-b-2 border-black"
                                    : "hover:underline"
                                }
                              >
                                {link.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>

                        {/* Show full list on smaller screens */}
                        <ul className="space-y-2 lg:hidden">
                          {section.links.map((link, i) => (
                            <li key={i}>
                              <NavLink
                                to={link.path}
                                className={({ isActive }) =>
                                  isActive
                                    ? "text-black border-b-2 border-black"
                                    : "hover:underline"
                                }
                              >
                                {link.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <ul className="space-y-2">
                        {section.links.map((link, i) => (
                          <li key={i}>
                            <NavLink
                              to={link.path}
                              className={({ isActive }) =>
                                isActive
                                  ? "text-black border-b-2 border-black"
                                  : "hover:underline"
                              }
                            >
                              {link.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
