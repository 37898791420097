import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ContactSection = () => {
  return (
    <section className="py-16" id="contact">
      <div className="container w-5/6 mx-auto">
        <h2 className="text-center font-extrabold text-gray-700 text-4xl/[1.07] tracking-tight md:text-5xl/[1.25]">
          Feel free to contact us at anytime.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* Dhaka Office */}
          <div>
            <div className="my-5 text-gray-700">
              <h3 className="text-2xl font-semibold mb-4">Dhaka Office</h3>
              <p>272/5, West Agargaon, Sher-e-Bangla Nagar, Dhaka-1207</p>
              <p className="text-gray-600">
                <i className="fa fa-envelope-o"></i>{" "}
                <a
                  href="mailto:netfeebd@gmail.com"
                  className="text-red-500 hover:underline"
                >
                  <FontAwesomeIcon icon={faEnvelope} /> netfeebd@gmail.com
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} /> 01896192227, 01896488481
              </p>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.0350680158153!2d90.36674007239229!3d23.781765540405996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c15e9f448fe7%3A0xea077001b58b5803!2sShunno%20IT%20Dhaka%20Office!5e0!3m2!1sen!2sbd!4v1727696934572!5m2!1sen!2sbd"
              width="100%"
              height="270"
              className="border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Dhaka Office"
            ></iframe>
          </div>

          {/* Rajshahi Office */}
          <div>
            <div className="my-5 text-gray-700">
              <h3 className="text-2xl font-semibold mb-4">Rajshahi Office</h3>
              <p>216/1, Talaimari, Kajla, Boalia, Rajshahi-6204</p>
              <p className="text-gray-600">
                <i className="fa fa-envelope-o"></i>{" "}
                <a
                  href="mailto:netfeebd@gmail.com"
                  className="text-red-500 hover:underline"
                >
                  <FontAwesomeIcon icon={faEnvelope} /> netfeebd@gmail.com
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} /> 01896192221, 01896192222
              </p>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.5991727393284!2d88.6215621744123!3d24.360451365084792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fbef00627d4f99%3A0xe73eeb888ab08b14!2sShunno%20IT%20Rajshahi%20Office!5e0!3m2!1sen!2sbd!4v1727696751423!5m2!1sen!2sbd"
              width="100%"
              height="270"
              className="border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Rajshahi Office"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
