//Home
import React from "react";
import Gallery from "./Gallery";
import HeroSection from "../components/HeroSection";
import SupportSection from "../components/support/SupportSection";
import ContactSection from "../components/contact/ContactSection";
import PricingSection from "../components/pricing/PricingSection";
import FeaturesSection from "../components/features/FeaturesSection";
import About from "./About";

const Home = () => {
  return (
    <div className="container mx-auto">
      <HeroSection />
      <div id="intro">
        <About />
      </div>
      <FeaturesSection />
      <SupportSection />
      <Gallery />
      <PricingSection />
      <ContactSection />
    </div>
  );
};

export default Home;
