import React from "react";
import { NavLink } from "react-router-dom";

const ReturnRefundPolicy = () => {
  return (
    <div
      data-aos="fade-down"
      className="return-refund-policy p-5 md:p-24 shadow text-gray-700 container md:w-5/6 mx-auto"
    >
      <h2 className="text-2xl font-bold md:mt-3 mt-16 mb-4">
        Return and Refund Policy
      </h2>
      <p className="mb-4">
        Thank you for choosing{" "}
        <span className="italic font-bold">NetFee ISP Billing Software</span>.
        Your trust in our service means a lot to us. If you are not completely
        satisfied with your purchase, we are here to assist you.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Service Refunds
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Refund requests for software subscription fees will be considered only
          if submitted within <span className="italic font-bold">7 days</span>{" "}
          of purchase and if no usage (e.g., user account creation, data input)
          has occurred.
        </li>
        <li>
          No refunds will be provided for services that have already been
          rendered, such as technical support or data migration.
        </li>
        <li>
          Refunds for custom features or integrations will depend on the stage
          of development and are subject to our discretion.
        </li>
      </ul>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Non-Refundable Items
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Licenses for downloadable software after activation.</li>
        <li>
          Services related to domain registration, hosting, or cloud storage
          once provisioned.
        </li>
        <li>Annual subscription plans paid in full.</li>
      </ul>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Return Process
      </p>
      <p className="mb-4">
        To initiate a refund, please contact our support team at{" "}
        <span className="italic font-bold">netfeebd@gmail.com</span> with the
        following details:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Proof of purchase (order ID, receipt, etc.).</li>
        <li>Reason for the refund request.</li>
      </ul>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Processing Time
      </p>
      <p className="mb-4">
        Refund requests are typically reviewed within{" "}
        <span className="italic font-bold">3–5 business days</span>. If
        approved, the refund will be processed to your original payment method.
        Please allow additional time for your bank or payment provider to post
        the refund.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Technical Issues
      </p>
      <p className="mb-4">
        If you experience any technical issues with the software, please reach
        out to our support team immediately. We are committed to resolving such
        matters promptly.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">Contact Us</p>
      <p className="mb-4">
        For any queries or further clarification, feel free to contact us:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Email:{" "}
          <a
            href="mailto:netfeebd@gmail.com"
            className="text-fuchsia-600 hover:underline"
          >
            netfeebd@gmail.com
          </a>
        </li>
        <li>
          Phone:{" "}
          <a
            href="tel:01896192222"
            className="text-fuchsia-600 hover:underline"
          >
            01896192222
          </a>
        </li>
      </ul>

      <NavLink
        to="/"
        className="inline-block bg-fuchsia-500 text-white py-3 px-6 rounded-lg font-semibold hover:bg-blue-600 mt-6"
      >
        Back to Home
      </NavLink>
    </div>
  );
};

export default ReturnRefundPolicy;
