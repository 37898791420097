import React from "react";
import { Link } from "react-router-dom";

function AppStore() {
  const appDetails = [
    {
      title: "NetFee V3.9",
      imgUrl: "/logo/netFee.png",
      imgAlt: "netFee logo",
      apkName: "NetFee",
    },
    {
      title: "NetFee Customer V2.1",
      imgUrl: "/logo/netFeeClient.png",
      imgAlt: "netFee customer logo",
      apkName: "NetFeeCustomer",
    },
  ];
  return (
    <div className="w-5/6 mx-auto mt-24 flex flex-wrap justify-center gap-4">
      {appDetails &&
        appDetails.map((app, index) => (
          <div
            data-aos="zoom-in"
            key={index}
            className="flex flex-col w-72 h-65 shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
          >
            <Link
              to={`/app-store/download/${app?.apkName}`}
              className="h-full flex flex-col items-center justify-between"
            >
              {/* App Image */}
              <img className="w-1/2 py-5" src={app?.imgUrl} alt={app?.imgAlt} />

              {/* App Name */}
              <div className="px-4 pb-5 text-center">
                <h2 className="text-lg font-semibold text-gray-800">
                  {app?.title}
                </h2>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
}

export default AppStore;
