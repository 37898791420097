//Features
import FeaturesSection from "../components/features/FeaturesSection";
const Features = () => {
  return (
    <section className="py-24" id="features">
      <FeaturesSection />
    </section>
  );
};

export default Features;
