import React from "react";
import { HiOutlineDownload } from "react-icons/hi";
import { useParams } from "react-router-dom";

function AppDetails() {
  const { appName } = useParams();

  const appDetails = [
    {
      title: "NetFee",
      description:
        "NetFee is a cutting-edge mobile app from Shunno IT designed to make your business transactions effortless. With seamless Webhook integration, you can automate repetitive tasks, get real-time updates, and manage all your essential tools in one place. Simplify your operations, save time, and boost productivity with Netfee. Download Netfee now and step into a smarter, faster way of managing your business!",
      imgUrl: "/logo/netFee.png",
      imgAlt: "NetFee logo",
      apkName: "NetFee",
      apkFile: "/NetFee.apk",
      version: "NetFee V3.9",
    },
    {
      title: "NetFee Customer",
      description:
        "Netfee is a powerful mobile app from Shunno IT designed to revolutionize how you manage business transactions. With seamless Webhook integration, you can automate tasks, receive real-time updates, and effortlessly connect with all your essential tools in one place. Experience smoother, faster, and more efficient business operations. Download Netfee today and take the first step towards smarter business management!",
      imgUrl: "/logo/netFeeClient.png",
      imgAlt: "NetFee Customer logo",
      apkName: "NetFeeCustomer",
      apkFile: "/NetFeeCustomer.apk",
      version: "NetFeeCustomer V2.1",
    },
  ];

  return (
    <div className="md:w-5/6 mx-auto md:mt-24 mt-16 p-4 " data-aos="zoom-in">
      {appDetails.map(
        (app, index) =>
          app?.apkName === appName && (
            <div
              key={index}
              className="bg-white rounded-lg border overflow-hidden md:flex md:space-x-4 space-y-2 md:space-y-0"
            >
              {/* App Image */}
              <div className="md:w-1/4 w-full flex justify-center p-4 items-center mx-auto">
                <img
                  src={app?.imgUrl}
                  alt={app?.imgAlt}
                  className="object-fit m-auto"
                  style={{ width: "200px" }}
                />
              </div>

              {/* App Content */}
              <div className="md:w-3/4 w-full p-6 md:px-10 bg-zinc-50">
                {/* App Name */}
                <h2 className="text-2xl font-bold text-gray-800 mb-3">
                  {app?.title}
                </h2>

                {/* Description */}
                <p className="text-gray-600 text-sm leading-relaxed mb-4">
                  {app?.description}
                </p>

                {/* Version & Download */}
                <div className="mt-4 text-center">
                  <p className="text-indigo-600 text-sm italic mb-2">
                    <span className="font-bold">App Version:</span>{" "}
                    {app?.version}
                  </p>
                  <a
                    href={app?.apkFile}
                    download={true}
                    className="inline-block bg-blue-600 flex items-center gap-2 text-white text-sm font-medium py-2 px-4 rounded-md hover:bg-blue-700 transition-all w-fit mx-auto"
                  >
                    Download <HiOutlineDownload />
                  </a>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
}

export default AppDetails;
